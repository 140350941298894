module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Darker+Grotesque:500,900","Raleway:300,500,700&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VI§VE Advokater","short_name":"VI§VE","start_url":"/","background_color":"#EFEFEF","theme_color":"#1e1d22","display":"standalone","icon":"src/images/vive-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d46c97348db4ff3ff865a1e84b35dccb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
